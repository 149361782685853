import api_client from "@/api_client";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { LoadingBarrier } from "./LoadingBarrier/LoadingBarrier";

import { lightTheme, darkTheme } from "@/helpers/mui-theme";

export enum ColorScheme {
  light,
  dark,
  system,
}

const colorSchemeFromString = (colorScheme: string) => {
  switch (colorScheme) {
    case "LIGHT":
      return ColorScheme.light;
    case "DARK":
      return ColorScheme.dark;
    case "SYSTEM":
      return ColorScheme.system;
    default:
      throw new Error("Unknown color scheme " + colorScheme);
  }
};

const ThemeWrapper = (props: { children: any; dependant?: boolean }) => {
  const { children, dependant } = props;

  if (dependant && !(window as any).pageSupportsSystemTheme) {
    return children;
  }

  (window as any).pageSupportsSystemTheme = true;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [colorScheme, setColorScheme] = React.useState<ColorScheme>(
    ColorScheme.light
  );
  const [themeRetrieved, setThemeRetrieved] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    getColorScheme();
  }, []);

  React.useEffect(() => {
    (window as any).colorScheme = getMode();
  }, [colorScheme, prefersDarkMode]);

  const getColorScheme = async () => {
    try {
      const { data }: { data: string } = await api_client.get(
        "/user_preferences/theme"
      );
      const scheme = colorSchemeFromString(data);
      setColorScheme((_) => scheme);
      setThemeRetrieved((_) => true);
    } catch (e) {
      setError(e);
    }
  };

  const getMode = () => {
    switch (colorScheme) {
      case ColorScheme.light:
        return "light";
      case ColorScheme.dark:
        return "dark";
      case ColorScheme.system:
        return prefersDarkMode ? "dark" : "light";
    }
  };

  const theme = createTheme({
    palette: {
      mode: getMode(),
      ...(getMode() === "light" ? lightTheme : darkTheme),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!dependant && !themeRetrieved && (
        <LoadingBarrier
          loading={true}
          message={
            error ? error?.message + ". " + error?.response?.data?.error : null
          }
        />
      )}
      {(themeRetrieved || dependant) && children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
